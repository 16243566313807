@import "../../../../../../styles/imports.scss";

.specificityWrapper {
  &.NumberInputContainer {
    position: relative;

    .NumberInput {
      border: 1px solid $border-lightgray;
      border-radius: 0.8em;
      display: flex;
      cursor: pointer;
      position: relative;
    }

    .leftContainer {
      padding: 0.5em 1.5em;
      display: flex;
      flex-direction: column;
      width: 90%;
    }

    .rightContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-right: 0.5em;
      .btnContainer {
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: contain;
        user-select: none;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.7em;
          color: $text-lightgray;
          &:first-child {
            margin-right: 1em;
          }
          &:active {
            color: rgb(206, 206, 206);
          }
          &.disabled {
            color: rgb(206, 206, 206);
          }
        }
      }
    }

    .label {
      color: $text-lightgray;
      font-size: 0.8em;
    }

    .number {
      font-weight: 500;
      font-size: 1.1em;
    }

    .dropdownContainer {
      // background-color: gray;
      border-radius: $dropdown-borderRadius;
      overflow: hidden;
      .dropdownListContainer {
        @include ulReset;
        max-height: 200px;
        overflow: auto;
        li {
          padding: 0.5em 1em;
          cursor: pointer;
          &:hover {
            background-color: $primary-blue;
            color: white;
          }
        }
      }
    }
  }
}
