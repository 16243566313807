// Text variables
$text-lightgray: #969696;

// Text Sizes
$font-size-xs: 0.8em;
$font-size-sm: 0.9em;
$font-size-md: 1.5em;
$font-size-lg: 1.8em;
$font-size-xl: 2em;

// Color Variables
$primary-lightblue: #8bb4f7;
$primary-blue: #005aeb;
$primary-yellow: #fece32;
$primary-red: #ff0000;
$primary-green: #316300;
$primary-gray: #2c2c2c;

// Util Variables
$boxshadow-light: 0px 0px 11px 0px rgba(0, 0, 0, 0.2);
$dropdown-borderRadius: 0.5em;
$container-default-borderRadius: 0.8em;

// other Variables
$border-lightgray: #d5d5d5;

:export {
  primaryLightblue: $primary-lightblue;
  primaryBlue: $primary-blue;
  primaryYellow: $primary-yellow;
  primaryRed: $primary-red;
  primaryGreen: $primary-green;
  primaryGray: $primary-gray;
  borderLightgray: $border-lightgray;
  textLightgray: $text-lightgray;
}
