@mixin ulReset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin descriptionForceStyles {
  & * {
    font-size: 0.9em;
    line-height: 1.8em;
    font-family: "Poppins";
  }
}
