@import "../../../../styles/imports.scss";

.specificityWrapper {
  &.locationDropdown {
    position: relative;
    height: 100%;
    width: 100%;
    .locationDropdownBox {
      background-color: white;
      height: 100%;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      display: flex;
      align-items: center;
      padding: 0.5em 1em;
      user-select: none;
      cursor: pointer;
      .icon {
        width: 15%;
      }
      .container {
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .label {
          width: 80%;
          // color: $text-lightgray;
          font-size: 0.8em;
          white-space: nowrap;
        }
        .selectedValue {
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .dropdownIcon {
        margin-left: auto;
        animation: rotate180b 0.2s normal;
        &Open {
          transform: rotateZ(180deg);
        }
      }
      @include mediaMinMedium {
        border-width: 1px;
        border-style: solid;
      }
    }

    .dropdownSelectContainer {
      border-radius: $dropdown-borderRadius;
      overflow: hidden;
      ul {
        list-style-type: none;
        padding: 0;
        overflow: hidden;
        margin: 0;
        max-height: 300px;
        overflow-y: auto;
        li {
          padding: 0.5em 0.5em;
          cursor: pointer;
          // &:hover {
          //   background-color: $primary-blue;
          //   color: white;
          // }
        }
        li.hotelOption {
          padding-left: 2em;
        }
      }
    }
  }
}
