@import "../../../../../../styles/imports.scss";

.specificityWrapper {
  &.AgeInputContainer {
    position: relative;

    .AgeInput {
      border: 1px solid $border-lightgray;
      border-radius: 0.8em;
      display: flex;
      overflow: hidden;
      cursor: pointer;
      padding: 0.8em 1.5em;
    }

    .leftContainer {
      display: flex;
      width: 90%;
    }

    .rightContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 10%;
      .arrowBtn {
        height: 50%;
        width: 100%;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        img {
          width: 1em;
        }
        &.opened {
          img {
            transform: rotateX(180deg);
          }
        }
      }
    }

    .label {
      font-weight: 500;
      &:last-child {
        margin-left: 0.5em;
      }
    }

    .dropdownContainer {
      border-radius: $dropdown-borderRadius;
      overflow: hidden;
      ul {
        @include ulReset;
        height: 400px;
        overflow: auto;
        li {
          padding: 0.5em 1em;
          cursor: pointer;
          // &:hover {
          //   background-color: $primary-blue;
          //   color: white;
          // }
        }
      }
    }
  }
}
