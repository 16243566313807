@import "../../styles/imports.scss";

.specificityWrapper {
  &.dropdownContainer {
    box-shadow: $boxshadow-light;
    background-color: white;
    z-index: 500;
    border-radius: $dropdown-borderRadius;
  }

  .defaultStyles {
    position: absolute;
    top: calc(100% + 0.5rem);
    width: 100%;
    left: 0;
    cursor: default;
    box-shadow: $boxshadow-light;
    background-color: white;
    z-index: 500;
    border-radius: $dropdown-borderRadius;
  }
}
