@import "../../styles/imports.scss";

.specificityWrapper {
  &.container {
    .customButton {
      min-height: 40px;
      padding: 0.5em 2em;
      border-radius: 5em;
      border: none;
      font-size: 0.85em;
      font-family: Poppins;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      p {
        margin: 0;
      }

      .icon {
        display: flex;
        align-items: center;
      }

      &:not(.noLabel):not(.isLeft) {
        img:first-child,
        svg {
          margin-right: 10px;
        }
      }
      // &.noLabel {
      //   padding: 0.6em;
      // }
    }

    .primary {
      // height: 40px;
      padding: 1em 1.5em;
    }

    .noWrap {
      white-space: nowrap;
    }

    .isLeft {
      & > :first-child {
        margin-right: 1em;
      }
    }
  }
}
